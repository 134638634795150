<template>
    <LiefengContent>
        <template v-slot:title>南沙区地名+管理</template>
        <template v-slot:toolsbarRight>
            <Button type="info" @click="append({})">新增</Button>
            <Button type="info" @click="jumpType()">类型管理</Button>
        </template>
        <template v-slot:contentArea>
            <LiefengTable
                :talbeColumns="talbeColumns"
                :tableData="tableData"
                height="200"
                :loading="loading"
                :fixTable="true"
                :pagesizeOpts="[20, 30, 50, 100]"
                :curPage="page"
                :total="total"
                :page-size="pageSize"
                @hadlePageSize="hadlePageSize"
            ></LiefengTable>
            <LiefengModal :title="currentFormData.eventId ? '修改' : '新增'" width="30%" height="250px" :value="addModal.status" @input="addModalData" class="export-modal">
                <template v-slot:contentarea>
                    <Form :model="currentFormData" :label-width="120" :rules="validateForm" class="form" ref="form">
                        <FormItem label="名称" prop="scenicName">
                            <Input placeholder="请输入名称" v-model.trim="currentFormData.scenicName"></Input>
                        </FormItem>
                        <FormItem label="来源项目">
                            <Input placeholder="请输入来源项目" v-model.trim="currentFormData.source"></Input>
                        </FormItem>
                    </Form>
                </template>
                <template #toolsbar>
                    <Button type="info" style="margin-right: 10px" @click="addModalData(false)">取消</Button>
                    <Button type="primary" icon="ios-share-outline" @click="submit">保存</Button>
                </template>
            </LiefengModal>

            <LiefengModal :title="'授权社区'" :value="setModal.status" @input="setModalData" class="export-modal">
                <template v-slot:contentarea>
                    <LiefengCascaderCity v-if="showCascader" @changeCasader="changeCasader" :resetNum="resetNum" :orgCode="4401" :width="400" :isClear="true"></LiefengCascaderCity>
                </template>
                <template #toolsbar>
                    <Button type="info" style="margin-right: 10px" @click="setModalData(false)">取消</Button>
                    <Button type="primary" icon="ios-share-outline" @click="setOrg">授权</Button>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import LiefengCascaderCity from "@/components/LiefengCascaderCity"
export default {
    components: { LiefengTable, LiefengContent, LiefengModal, LiefengCascaderCity },
    data() {
        return {
            talbeColumns: [
                {
                    title: "村居名称",
                    align: "center",
                    minWidth: 180,
                    key: "scenicName",
                },
                {
                    title: "来源项目",
                    align: "center",
                    minWidth: 180,
                    key: "source",
                },
                {
                    title: "添加时间",
                    align: "center",
                    minWidth: 180,
                    key: "gmtCreate",
                    render: (h, params) => {
                        return h("span", {}, params.row.gmtCreate && params.row.gmtCreate != "" ? this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd hh:mm:ss") : "")
                    },
                },
                {
                    title: "已授权社区",
                    align: "center",
                    minWidth: 180,
                    key: "orgName",
                },
                {
                    title: "是否启用",
                    minWidth: 180,
                    align: "center",
                    render: (h, params) => {
                        return h("i-switch", {
                            props: {
                                value: params.row.enable == 1,
                            },
                            on: {
                                "on-change": value => {
                                    this.currentFormData = {
                                        scenicId: params.row.scenicId, //景区id
                                        scenicName: params.row.scenicName, //景区名称
                                        scenicType: params.row.scenicType, //景区类型；1：人文逢源；2：南沙地名
                                        parentId: params.row.parentId, //父id。一级默认0
                                        orgCode: params.row.orgCode, //所属机构编码
                                        enable: value ? 1 : 0, //是否启用。0：禁用；1：启用
                                        backgroundImage: params.row.backgroundImage, //背景图
                                        source: params.row.source,
                                    }
                                    this.submit(true)
                                },
                            },
                        })
                    },
                },
                {
                    title: "操作",
                    align: "center",
                    width: 300,
                    fixed: "right",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    textAlign: "center",
                                },
                            },
                            [
                                // h(
                                //     "span",
                                //     {
                                //         on: {
                                //             click: async () => {
                                //                 this.jumpType(params.row.scenicId)
                                //             },
                                //         },
                                //         style: {
                                //             width: "65px",
                                //             marginRight: "10px",
                                //             textAlign: "center",
                                //             color: "#2d8cf0",
                                //             cursor: "pointer",
                                //             userSelect: "none",
                                //         },
                                //     },
                                //     "类型管理" //1001
                                // ),
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: async () => {
                                                this.contentType(params.row.scenicId, params.row.orgCode, params.row.scenicName, params.row.orgName, this.$route.query.parentId)
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                            display: params.row.orgCode && params.row.orgCode != "" ? "inline-block" : "none",
                                        },
                                    },
                                    "内容管理" //1001
                                ),
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: async () => {
                                                this.currentFormData = {
                                                    scenicId: params.row.scenicId, //景区id
                                                    scenicName: params.row.scenicName, //景区名称
                                                    scenicType: params.row.scenicType, //景区类型；1：人文逢源；2：南沙地名
                                                    parentId: params.row.parentId, //父id。一级默认0
                                                    enable: params.row.enable, //是否启用。0：禁用；1：启用
                                                    backgroundImage: params.row.backgroundImage, //背景图
                                                    source: params.row.source,
                                                }
                                                this.setModalData(true)
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "授权" //1001
                                ),
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: async () => {
                                                this.currentFormData = {
                                                    scenicId: params.row.scenicId, //景区id
                                                    scenicName: params.row.scenicName, //景区名称
                                                    scenicType: params.row.scenicType, //景区类型；1：人文逢源；2：南沙地名
                                                    parentId: params.row.parentId, //父id。一级默认0
                                                    orgCode: params.row.orgCode, //所属机构编码
                                                    enable: params.row.enable, //是否启用。0：禁用；1：启用
                                                    backgroundImage: params.row.backgroundImage, //背景图
                                                    source: params.row.source,
                                                }
                                                this.addModalData(true)
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "修改" //1001
                                ),
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: async () => {
                                                this.deleteData(params.row.scenicId)
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "删除" //1001
                                ),
                            ]
                        )
                    },
                },
            ],
            tableData: [],
            loading: false,
            page: 1,
            pageSize: 20,
            total: 0,

            currentFormData: {},
            validateForm: {
                scenicName: [{ required: true, message: "请输入名称", trigger: "blur" }],
                // eventDesc: [{ required: true, message: "请输入来源项目", trigger: "blur" }],
            },

            addModal: {
                status: false,
            },
            setModal: {
                status: false,
            },
            showCascader: true,
            resetNum: 1, // 是否清空当前社区选择
            cascaderList: [],
        }
    },
    created() {
        this.getList()
    },
    methods: {
        changeCasader(val) {
            this.cascaderList = val
            if (!val.length) {
                this.resetNum++
            }
        },
        // 授权机构按钮
        setOrg() {
            if (this.cascaderList.length != 0 && this.cascaderList.length != 3) {
                this.$Message.warning({
                    content: "请选择到社区进行查询",
                    background: true,
                })
                return
            }
            this.currentFormData.orgCode = this.cascaderList[this.cascaderList.length - 1]
            this.submit(true)
            this.setModalData(false)
        },
        setModalData(status) {
            this.setModal.status = status
            if (status) {
                this.showCascader = false
                this.$nextTick(() => {
                    this.showCascader = true
                })
            }
        },
        contentType(scenicId, orgCode, scenicName, orgName, parentId) {
            this.$core.openLayerFrame({
                type: 2,
                title: "内容管理",
                content: `/page#/placenamecontentindex?scenicId=${scenicId}&orgCode=${orgCode}&scenicName=${scenicName}&orgName=${orgName}&parentId=${parentId}`,
                area: ["100%", "100%"],
            })
        },
        // 跳转类型管理
        jumpType(scenicId) {
            this.$core.openLayerFrame({
                type: 2,
                title: "类型管理",
                content: `/page#/placenametypeindex?scenicId=${this.$route.query.parentId}`,
                area: ["100%", "100%"],
            })
        },
        append() {
            this.currentFormData = {
                source: "", // 来源
                scenicName: "", //景区名称
                scenicType: "2", //景区类型；1：人文逢源；2：南沙地名
                parentId: this.$route.query.parentId, //父id。一级默认0
                orgCode: "", //所属机构编码
                enable: 1, //是否启用。0：禁用；1：启用
                backgroundImage: "", //背景图
            }

            this.addModalData(true)
        },
        deleteData(id) {
            this.$Modal.confirm({
                title: "删除选项",
                content: "删除后无法恢复，是否继续删除？",
                okText: "确认删除",
                cancelText: "取消",
                onOk: () => {
                    this.$post("/gateway/api/syscenic/pc/deleteScenic", {
                        scenicId: id,
                    }).then(res => {
                        var that = this
                        if (res && res.code == 200) {
                            this.$Message.success({
                                content: "删除成功",
                                background: true,
                            })
                            that.getList()
                            return
                        }
                    })
                },
            })
        },
        submit(enable) {
            if (enable) {
                this.submitPost()
                return
            }
            this.$refs.form.validate(status => {
                if (status) {
                    this.submitPost()
                }
            })
        },
        submitPost() {
            var cFormData = JSON.parse(JSON.stringify(this.currentFormData))
            var url = ""
            if (cFormData.scenicId && cFormData.scenicId != "") {
                url = "/gateway/api/syscenic/pc/updateScenic"
            } else {
                url = "/gateway/api/syscenic/pc/addScenic"
            }
            this.$post(
                url,
                {
                    ...cFormData,
                },
                { "content-type": "application/json" }
            )
                .then(res => {
                    this.addModalData(false)
                    if (res.code == 200) {
                        this.$Message.success({
                            background: true,
                            content: "操作成功",
                        })
                        this.currentFormData = {}
                        this.getList(this.page)
                    } else {
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$Message.error({
                        background: true,
                        content: "保存失败，请联系管理员处理",
                    })
                })
        },
        addModalData(status) {
            this.addModal.status = status
        },
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        // 接口部分
        getList() {
            this.loading = true
            this.$get("/gateway/api/syscenic/pc/selectScenicByPage", {
                page: this.page,
                pageSize: this.pageSize,
                parentId: this.$route.query.parentId,
            }).then(res => {
                if (res.code == 200 && res.dataList) {
                    this.loading = false
                    this.tableData = res.dataList.map(item => {
                        return {
                            ...item,
                        }
                    })
                }
                this.total = res.maxCount
            })
        },
    },
}
</script>

<style>
</style>